import React, { useEffect, useState } from "react";
import { MultiSelect, PasswordInput, Container, Grid, Title, Menu, UnstyledButton, Tabs, Box, TextInput, Switch, Select, Button, Card, Group, Text, Center } from '@mantine/core';
import QRCode from "react-qr-code";
import { useNavigate, useSearchParams,useLocation  } from 'react-router-dom';

const SharePage = () => {
    const navigate=useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const user = searchParams.get('u')
    const pass = searchParams.get('p')
    console.log(`${window.location.host}/autoLogin?u=${user}&p=${pass}`)
    return <>
        <Center style={{ width: '100%', marginTop: 30, marginBottom: 20, flexDirection: 'column' }}>
            <Text weight={700} sx={(theme) => ({
                zIndex: '100',
                textAlign: 'center',
                color: 'white',
                fontSize: '60px',
                letterSpacing: 2,
                bold: true,
                width: '450px',
                maxWidth: '100%',
                marginBottom: '20px',
            })}
            >
                HKES
            </Text>
            <QRCode value={`${window.location.host}/autoLogin?u=${user}&p=${pass}`} />
        </Center>
    </>
}
export default SharePage