import { Box, Avatar, Center, Container, Grid, Space, Title, Input, Select, Group, Text, Button, Collapse, Textarea } from "@mantine/core";
import { ChevronDown, BrandTwitter, BrandFacebook, Building, Logout, BrandLinkedin, BrandOffice } from 'tabler-icons-react';
import { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import './onboarding.css';
import useUserStore from '../hooks/useUserStore';
import useCURDStore from "../hooks/useCURDStore";
import useLoginStore from "../hooks/useLoginStore";

// const role = [
// {
// 	image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAADFElEQVR4nO2az0sUYRjHP+/s7uzKkr+6KLbeOngyisCiX6cK6hpSGSTegiCwSOpQdumS+B9UB4nIawc7SBBFHTK6RHg2skstapIz2850GHXd+aGjvTvvqu8H3oPPK8/7fb88z8zLOwsajUaj0Wh2K0JapiMXGrAyD0H0gtsmLS8A4gc4z8n+vcP78T8yM6elZbLMR+BeA1daygpuG4gbWJk0cF1mZkNeKrdXXq5ILspOKNEA9krMldga8lrAz6dncp4vBy/VoqdWkVkB2xJtgGoBqvnvPrVf8g44GjH91jzP8XrK60dGBUSJBDhWh3mrCK+A/pEChjOKEKeBPbIWU8QCgknK5SEe3572TwYN6B8pkHI/A61JqEuQImXRzZPBmbXBYAsYzig7b/MALaTdEX8waIBX9juVM/5A2ENwu/d8NC6N/tCuPwdoA1QLUI02QLUA1WgDVAtQjTZAtQDVBO8EOzrAtivDssBxpC1oGmXud01wuTBFe24egNmlRsZmDjH89Sy2k5K2FoaAjAnm8shmA/8SNKCzs9qAJQtsqzpm2+Bu7a7yXtcEN/e/roq15+a5tRy7++XclvIigHSmslnThGwOsps1IJWChgZvrFAqbWxKqRRLZ19hKnLuSuFjfANSKd9ms95YGzNNEOtfesW7Fs9kvJHPe3+7Dth+U5Zitc5K2YfRllsInwgrZf9ms1kwNv9I29p3AWFURKxQLsdrnQ1zE6+U03I+acj7MBKndSxr4zz7CsEyjlHKW6V2X4Yg2DpOjAdnodMr+YRI9hwQZ2MJbh70QUgboA1IesHZUkvk3PdS8rfxiRswVjy1ztzJBJV41PY1GMLwwgC4Dn2tb2jPFAGvKsZ+neDB7wFoSlZP8J3z9ENNf5GB68LcHCwueqdH8A5R+Tw0NdXswLPK1Z6qBRKvAISA5mZv1AH6LaBagGq0AaoFqCbMgIhbiR3BnD8QZsBkAkIUIV75I0EDyqkhoJiEnIT5Sdod9AeDBgwcnsYxuhGMA9EXeNuHeRAvSHOAvp5vqsVoNBqNRqOpH/4BTcnk/EnYi7IAAAAASUVORK5CYII=',
// 	label: 'Organization',
// 	value: 'organization',
// 	description: 'Our admin will process your account and set up your organization.',
// },
// {
// 	image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAYAAABzJ5OsAAAABmJLR0QA/wD/AP+gvaeTAAADIElEQVRoge2Zz0sUYRjHv+/Mu+3v3VkXK7MVPRSamWYYGigdhA5dOgURXbt0CPwDukh/Ql2CLhqBEEWHKCqI6HckiBhGh6RNTSvbXWd/uDszbwdzc2dmZ3eYWcdwP6fled953s88+8zLywyBDk8Hupspka4xhmEQ+DfibULolYvjT+hdYwqeQ/TGiOGUlcvXwVYzaQCPJY5darn/ckE9h+rmJtJtBgyCWNa0ih/AGaqQCICT6kFOHXhyvD0KYBAACrKMXF6quWEVDMVPDTSogxp5Qqmw8TuTK0DM5WstVg1kF0ci6qBGfjMMBGC1M7KKofx2py7vFM7LM4bsg7fI3HkBKOYeMN19fqtgawWINx8i//4TAED6uoTAxdPg/J6qrnes8spyAsmrt4riAFCY/oLk6Djkbz+ryuFM5RWG5Og4lHROO/T3piArFdM4I8+YrnhxeK1QVRrnH1gL1OWdwlLPB85egKd/yC6XElaujEARVw3nWJLndzfBdaDdSgqD5JXVdm7bbEaSJMQXvkNRyu/PHMchtm8vKKVYXPqBTDZbHBPCIUQjQtlr9bBNPp3NYi4+D8bKn08IIYhGBISCAczF55HN/dvrM9mcc/LhYBBD/X1grHzlCeHA8+ud2n+sG7IsF8d4al7FNnnGGBKpVIkQpRQRIQwCYFVMl1RaTSgYgMftNrWmbfKJZApTM7OaeF9PFwJ+HyanZyAbnFciQhhHD3eYWtM2eSEcQk9nOyRV5YOB9dc+vV2dFStvFtvkCSEIh0Kanpek9VcnXo8bPq+32POMse3T88nUKianP1bcbXqPHEI4GMSbD1Ml/0RjtAFdHQdNrWmbvN/nRWusueI+7/f6AACtsWbNPm8W2+QppWhr2V/1/KY9jZbX3LnHA3l5EYXP2u3RFuTK70gtyYsTYxAnxqyksMR/3TZ1eaeoyztFXd4p6vJOUZd3CkN5AoZt8BW8LJqDGZOkBCgPAPC5XWY/E9UKllfYb3VQU/nhd7O/APIcAFyUh9vFb4WcMQTPYo9er6jDum1DSeEcQO4BEGsuZowI4K4rT87rDf4BOVP7WiL8dmkAAAAASUVORK5CYII=',
// 	label: 'Student',
// 	value: 'student',
// 	description: '',
// },
// {
// 	image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAYAAABzJ5OsAAAABmJLR0QA/wD/AP+gvaeTAAADIElEQVRoge2Zz0sUYRjHv+/Mu+3v3VkXK7MVPRSamWYYGigdhA5dOgURXbt0CPwDukh/Ql2CLhqBEEWHKCqI6HckiBhGh6RNTSvbXWd/uDszbwdzc2dmZ3eYWcdwP6fled953s88+8zLywyBDk8Hupspka4xhmEQ+DfibULolYvjT+hdYwqeQ/TGiOGUlcvXwVYzaQCPJY5darn/ckE9h+rmJtJtBgyCWNa0ih/AGaqQCICT6kFOHXhyvD0KYBAACrKMXF6quWEVDMVPDTSogxp5Qqmw8TuTK0DM5WstVg1kF0ci6qBGfjMMBGC1M7KKofx2py7vFM7LM4bsg7fI3HkBKOYeMN19fqtgawWINx8i//4TAED6uoTAxdPg/J6qrnes8spyAsmrt4riAFCY/oLk6Djkbz+ryuFM5RWG5Og4lHROO/T3piArFdM4I8+YrnhxeK1QVRrnH1gL1OWdwlLPB85egKd/yC6XElaujEARVw3nWJLndzfBdaDdSgqD5JXVdm7bbEaSJMQXvkNRyu/PHMchtm8vKKVYXPqBTDZbHBPCIUQjQtlr9bBNPp3NYi4+D8bKn08IIYhGBISCAczF55HN/dvrM9mcc/LhYBBD/X1grHzlCeHA8+ud2n+sG7IsF8d4al7FNnnGGBKpVIkQpRQRIQwCYFVMl1RaTSgYgMftNrWmbfKJZApTM7OaeF9PFwJ+HyanZyAbnFciQhhHD3eYWtM2eSEcQk9nOyRV5YOB9dc+vV2dFStvFtvkCSEIh0Kanpek9VcnXo8bPq+32POMse3T88nUKianP1bcbXqPHEI4GMSbD1Ml/0RjtAFdHQdNrWmbvN/nRWusueI+7/f6AACtsWbNPm8W2+QppWhr2V/1/KY9jZbX3LnHA3l5EYXP2u3RFuTK70gtyYsTYxAnxqyksMR/3TZ1eaeoyztFXd4p6vJOUZd3CkN5AoZt8BW8LJqDGZOkBCgPAPC5XWY/E9UKllfYb3VQU/nhd7O/APIcAFyUh9vFb4WcMQTPYo9er6jDum1DSeEcQO4BEGsuZowI4K4rT87rDf4BOVP7WiL8dmkAAAAASUVORK5CYII=',
// 	label: 'Teacher',
// 	value: 'teacher',
// 	description: '',
// },
// ];

const getYears = () => {
  const years = [];
  for (let i = 2022; i <= new Date().getFullYear() + 15; i++) {
    years.push({ label: i, value: i });
  }
  return years;
}

const SelectItem = forwardRef(
  ({ image, label, description, ...others }, ref) => (<div ref={ref} {...others}>
    <Group noWrap>
      <Avatar src={image} />
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
  )
);

const Onboarding = () => {
  const navigate = useNavigate();
  const [user, fetchUser] = useUserStore();
  const logout = useLoginStore();
  const { resources: schools, listResources: getSchools } = useCURDStore({ resourcesName: 'schools?all=true&disabled=false' });
  const [userFormData, setUserFormData] = useState({
    firstName: user.first_name,
    lastName: user.last_name,
    picture: user.picture,
    email: user.email,
    role: user.role,
    pronouns: 'He/His',
    student_school_name: user.student_school_name,
    organization_organziation_name: user.organization_organziation_name,
  })
  const [opened, setOpen] = useState(false);
  const [studentOpened, setStudentOpen] = useState(false);
  const handleRoleChange = (e) => {
    if (e === 'student') {
      setOpen(true)
      setStudentOpen(true)
      setUserFormData({
        ... userFormData,
        role: ['STUDENT'],
      })
    } else if ( e === 'teacher') {
      setOpen(true)
      setStudentOpen(false)
      setUserFormData({
        ... userFormData,
        role: ['TEACHER'],
      })
    } else {
      setOpen(false)
      setStudentOpen(false)
      setUserFormData({
        ... userFormData,
        role: ['ORGANIZATION'],
      })
    }
  }
  const handleSchoolChange = (school) => {
    setUserFormData({
      ... userFormData,
      school_id: school,
    })
  }
  const handlePronounChange = (pronoun) => {
    setUserFormData({
      ... userFormData,
      pronouns: pronoun.target.value,
    })
  }
  const handleInput = (input, label) => {
    setUserFormData({
      ... userFormData,
      [label]: input,
    })
  }
  const handleSubmit = async () => {
    await api().post('users/me', userFormData).then(() => {
      fetchUser().then(() => {
        console.log('completed')
        navigate('/')
      })
    })
  }
  return (
		<div className="root">
      <Container className="login" sx={(theme) => ({ alignSelf: 'center', padding: '0 5rem' })}>
        <Grid columns={24} spacing="xl" style={{ height: '100%' }}>
          <Grid.Col xs={24} sm={10} sx={(theme) => ({
            alignItems: 'stretch'
          })}>
            <Center style={{ width: '100%', height: '100%' }}>
              <Box sx={(theme) => ({
                borderRadius: theme.radius.md,
                backgroundColor: theme.colors.brand[0],
                padding: theme.spacing.xl,
                minHeight: '50%',
                minWidth: '80%'
              })}>
                <Center style={{ alignItems: 'center', width:'100%' }}>
                  <div>
                    <Title order={1} sx={(theme) => ({
                      color: theme.colors.black[6],
                    })}>Welcome, {user.first_name}</Title>
                    <Space h="sm" />
                    <Title order={3} sx={(theme) => ({
                      color: theme.colors.black[6],
                    })}>We are happy to see you here!</Title>
                    <Center><Avatar color="blue" size="xl"
                      sx={(theme) => ({
                        height: 170,
                        width: 170,
                        borderRadius: '50%',
                        marginTop: 50,
                        marginBottom: 50,
                      })}
                      src={user.picture}
                    /></Center>
                    <Center>
                      <Button leftIcon={<Logout />} color="red" onClick={() => logout()} > Logout </Button>
                    </Center>
                  </div>
                </Center>
              </Box>
            </Center>
          </Grid.Col>


          <Grid.Col xs={24} sm={14} sx={(theme) => ({
            alignItems: 'stretch'
          })}>
            <Center style={{ width: '100%', minHeight: '90vh' }}>
              <Box sx={(theme) => ({
                backgroundColor: theme.colors.teal[0],
              })}>
                <Title order={1} sx={(theme) => ({
                  color: theme.colors.black[4],
                })}>👋 Tell us about you</Title>
                <Space h="sm" />
                <Title order={3} sx={(theme) => ({
                  color: theme.colors.black[3],
                })}>Please fill in some basic details to get started</Title>
                <Space h="lg" />

                <Input.Wrapper 
                  label="Name"
                  required
                >
                  <Input
                    placeholder="Name"
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    defaultValue={user.first_name}
                    onChange={(e) => handleInput(e.target.value, 'firstName')}
                  />
                </Input.Wrapper >
                <Space h="sm" />

                <Input.Wrapper 
                  label="Set a password"
                  required
                >
									<Input type="password" placeholder="Password" />
                </Input.Wrapper >
                <Space h="sm" />


                {/* <Input.Wrapper 
                  id='input-demo'
                  required
                  label="I am..."
                > */}

                  {/* <Select
                    placeholder="Please select"
                    itemComponent={SelectItem}
                    data={role}
                    maxDropdownHeight={400}
                    onChange={handleRoleChange}
                  /> */}
                {/* </Input.Wrapper > */}

                <Space h="sm" />

                {/* <Input.Wrapper 
                  id="input-demo"
                  required
                  label="Pronouns"
                >
                  <Input
                    component="select"
                    rightSection={<ChevronDown />}
                    onChange={handlePronounChange}
                  >
                    <option value="He/Him">He/Him</option>
                    <option value="She/Her">She/Her</option>
                    <option value="They/Them">They/Them</option>
                    <option value="He/They">He/They</option>
                    <option value="She/They">She/They</option>
                  </Input>
                </Input.Wrapper > */}
                <Space h="sm" />

                <Collapse in={opened}>
                  <Input.Wrapper 
                    id="input-demo"
                    required
                    label="School"
                  >
                    <Select
                      placeholder="Please select your school"
                      searchable
                      nothingFound="No options"
                      maxDropdownHeight={280}
                      data={schools?.map(s => ({ label: s.name, value: s.id }))}
                      onChange={handleSchoolChange}
                    />
                  </Input.Wrapper >
                  <Space h="sm" />
                </Collapse>

                <Collapse in={studentOpened}>
                  <Input.Wrapper 
                    required
                    label="School Year"
                  >
                    <Select
                      label="Your graduation year"
                      placeholder="Pick one"
                      onChange={(e) => handleInput(e, 'student_graduation_year')}
                      data={getYears()}
                    />
                  </Input.Wrapper >
                  <Space h="sm" />
                </Collapse>

                <Input.Wrapper 
                  id="input-demo"
                  label="LinkedIn"
                >
                  <Input
                    icon={<BrandLinkedin size={16} />}
                    placeholder="LinkedIn"
                    rightSectionWidth={70}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    onChange={(e) => handleInput(e.target.value, 'twitter')}
                  />
                </Input.Wrapper >
                <Space h="sm" />

                <Input.Wrapper 
                  id="input-demo"
                  label="Company Name"
                >
                  <Input
                    icon={<Building size={16} />}
                    placeholder="Your Company"
                    rightSectionWidth={70}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    onChange={(e) => handleInput(e.target.value, 'facebook')}
                  />
                </Input.Wrapper >
                <Space h="sm" />

                <Textarea
                  placeholder="Under 100 words"
                  label="Please describe yourself"
                  onChange={(e) => handleInput(e.target.value, 'about')}
                />
                <Space h="xl" />

                <Button onClick={handleSubmit} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Submit</Button>
              </Box>

            </Center>
          </Grid.Col>
        </Grid>
      </Container >
		</div>
  )
}

export default Onboarding;