import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
	token: '',
  user: {
    id: '',
    name: '',
    email: '',
    role: [''],
  },
  isAuthenticated: false,
});

// export const countUp = () => {
//   setGlobalState('count', (v) => v + 1);
// };

// export const countDown = () => {
//   setGlobalState('count', (v) => v - 1);
// };

const setGlobalStateWithLocalStorage = (key, value) => {
	if (typeof window !== 'undefined') {
		localStorage.setItem(key, JSON.stringify(value));
	}
	setGlobalState(key, value);
}

const useGlobalStateWithLocalStorage = (key) => {
	if (typeof window !== 'undefined') {
		const value = localStorage.getItem(key);
		if (value) {
			console.log('hi' + key)
			// override the global state if it is different from the local storage
			// eslint-disable-next-line react-hooks/rules-of-hooks
			// if (JSON.stringify(value) !== JSON.stringify(useGlobalState(key))) {
			// 	setGlobalState(key, JSON.parse(value));
			// }
			return JSON.parse(value);
		} else {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return [useGlobalState(key)];
		}
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return [useGlobalState(key)];
}

export {
	useGlobalStateWithLocalStorage,
	setGlobalStateWithLocalStorage
};
