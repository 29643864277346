import { useState } from 'react';
import axios from '../api';

const useFieldStore = () => {
	const [candidate_extra_field, setCandidate_extra_field] = useState([
		{id:'1',label:'source', type: 'text box', active: true},
	])
	const [job_extra_field, setJob_extra_field] = useState([
		{id:'1',label:'job', type: 'text box', active: true},
	])
	const [client_extra_field, setClient_extra_field] = useState([
		{id:'1',label:'client', type: 'text box', active: true},
	])
	const [candidateAssessment_extra_field, setCandidateAssessment_extra_field] = useState([
		{id:'1',label:'Availability', type: 'text box', active: true},
		{id:'2',label:'Preferred Start Date', type: 'text box', active: true},
		{id:'3',label:'Cultural Style', type: 'text box', active: true},
		{id:'4',label:'Leadership Potential', type: 'text box', active: true},
		{id:'5',label:'Communication Skill', type: 'text box', active: true},
		{id:'6',label:'Teamwork Abilities', type: 'text box', active: true},
		{id:'7',label:'Online Portolio', type: 'text box', active: true},
		{id:'8',label:'Criminal Record Check Status', type: 'text box', active: true},
		{id:'9',label:'Eductaion Verification Status', type: 'text box', active: true},
		{id:'10',label:'Employment Verification Status', type: 'text box', active: true},
		{id:'11',label:'Personality Test Result', type: 'text box', active: true},
		{id:'12',label:'Technical Assessment Results', type: 'text box', active: true},
		{id:'13',label:'Psychometric Test Results', type: 'text box', active: true},
	])


	const getCandidateExtraField = async (id) => {
		const { data } = await axios().get(`/jobs/${id}/shortlist`);
		setCandidate_extra_field(data || []);
	}

	const addCandidateExtraField = async (data) => {
		console.log(data)
		setCandidate_extra_field([...candidate_extra_field,data])
	}
	const editCandidateExtraFieldByID = async (data) => {
		// await axios().put(`/jobs/${jobID}/shortlist/${candidateID}`, {
		// 	Status: status,
		// });
		const newcandidate_extra_field=candidate_extra_field
		newcandidate_extra_field.forEach((item)=>{
			if(item.id===data.id){
				item.label=data.label
			}
		})
		setCandidate_extra_field([...newcandidate_extra_field])
	}

	const removeCandidateExtraFieldByID = async (id) => {
		// await axios().delete(`/jobs/${jobID}/shortlist/${candidateID}`);
		// alert('Candidate removed from job');
		// await getCandidateExtraField(jobID);
		const newcandidate_extra_field=candidate_extra_field
		let deleIndex = 0
		console.log(id)
		newcandidate_extra_field.forEach((item,index)=>{
			if(item.id===id){
				deleIndex=index
			}
		})
		newcandidate_extra_field.splice(deleIndex,1)
		console.log(newcandidate_extra_field)
		setCandidate_extra_field([...newcandidate_extra_field])
	}

	
	const getJobExtraField = async (id) => {
		const { data } = await axios().get(`/jobs/${id}/shortlist`);
		setJob_extra_field(data || []);
	}

	const addJobExtraField = async (data) => {
		console.log(data)
		setJob_extra_field([...job_extra_field,data])
	}
	const editJobExtraFieldByID = async (data) => {
		// await axios().put(`/jobs/${jobID}/shortlist/${JobID}`, {
		// 	Status: status,
		// });
		const newJob_extra_field=job_extra_field
		newJob_extra_field.forEach((item)=>{
			if(item.id===data.id){
				item.label=data.label
			}
		})
		setJob_extra_field([...newJob_extra_field])
	}

	const removeJobExtraFieldByID = async (id) => {
		// await axios().delete(`/jobs/${jobID}/shortlist/${JobID}`);
		// alert('Job removed from job');
		// await getJobExtraField(jobID);
		const newJob_extra_field=job_extra_field
		let deleIndex = 0
		console.log(id)
		newJob_extra_field.forEach((item,index)=>{
			if(item.id===id){
				deleIndex=index
			}
		})
		newJob_extra_field.splice(deleIndex,1)
		console.log(newJob_extra_field)
		setJob_extra_field([...newJob_extra_field])
	}


	const getClientExtraField = async (id) => {
		const { data } = await axios().get(`/jobs/${id}/shortlist`);
		setClient_extra_field(data || []);
	}

	const addClientExtraField = async (data) => {
		console.log(data)
		setClient_extra_field([...client_extra_field,data])
	}
	const editClientExtraFieldByID = async (data) => {
		// await axios().put(`/jobs/${jobID}/shortlist/${ClientID}`, {
		// 	Status: status,
		// });
		const newClient_extra_field=client_extra_field
		newClient_extra_field.forEach((item)=>{
			if(item.id===data.id){
				item.label=data.label
			}
		})
		setClient_extra_field([...newClient_extra_field])
	}

	const removeClientExtraFieldByID = async (id) => {
		// await axios().delete(`/jobs/${jobID}/shortlist/${ClientID}`);
		// alert('Client removed from job');
		// await getClientExtraField(jobID);
		const newClient_extra_field=client_extra_field
		let deleIndex = 0
		console.log(id)
		newClient_extra_field.forEach((item,index)=>{
			if(item.id===id){
				deleIndex=index
			}
		})
		newClient_extra_field.splice(deleIndex,1)
		console.log(newClient_extra_field)
		setClient_extra_field([...newClient_extra_field])
	}

	const getCandidateAssessmentExtraField = async (id) => {
		const { data } = await axios().get(`/jobs/${id}/shortlist`);
		setCandidateAssessment_extra_field(data || []);
	}

	const addCandidateAssessmentExtraField = async (data) => {
		console.log(data)
		setCandidateAssessment_extra_field([...candidateAssessment_extra_field,data])
	}
	const editCandidateAssessmentExtraFieldByID = async (data) => {
		// await axios().put(`/jobs/${jobID}/shortlist/${CandidateAssessmentID}`, {
		// 	Status: status,
		// });
		const newCandidateAssessment_extra_field=candidateAssessment_extra_field
		newCandidateAssessment_extra_field.forEach((item)=>{
			if(item.id===data.id){
				item.label=data.label
			}
		})
		setCandidateAssessment_extra_field([...newCandidateAssessment_extra_field])
	}

	const removeCandidateAssessmentExtraFieldByID = async (id) => {
		// await axios().delete(`/jobs/${jobID}/shortlist/${CandidateAssessmentID}`);
		// alert('CandidateAssessment removed from job');
		// await getCandidateAssessmentExtraField(jobID);
		const newCandidateAssessment_extra_field=candidateAssessment_extra_field
		let deleIndex = 0
		console.log(id)
		newCandidateAssessment_extra_field.forEach((item,index)=>{
			if(item.id===id){
				deleIndex=index
			}
		})
		newCandidateAssessment_extra_field.splice(deleIndex,1)
		console.log(newCandidateAssessment_extra_field)
		setCandidateAssessment_extra_field([...newCandidateAssessment_extra_field])
	}


	return {
		candidate_extra_field,
		getCandidateExtraField,
		addCandidateExtraField,
		editCandidateExtraFieldByID,
		removeCandidateExtraFieldByID,

		job_extra_field,
		getJobExtraField,
		addJobExtraField,
		editJobExtraFieldByID,
		removeJobExtraFieldByID,
		
		client_extra_field,
		getClientExtraField,
		addClientExtraField,
		editClientExtraFieldByID,
		removeClientExtraFieldByID,

		candidateAssessment_extra_field,
		addCandidateAssessmentExtraField,
		getCandidateAssessmentExtraField,
		editCandidateAssessmentExtraFieldByID,
		removeCandidateAssessmentExtraFieldByID
	}
}
export default useFieldStore
