import AdminListView from "../components/adminListView";
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Badge, ActionIcon, Group, Space, Button, Modal, Text, Input, ScrollArea, Select, Center, Title } from "@mantine/core";
import { Dots, Edit, Eye, Search, Trash } from 'tabler-icons-react';
import Header from "../components/header";
import { useState, useEffect } from "react";
import { Viewer } from "@react-pdf-viewer/core";

const NotificationList = (props) => {
  const navigate = useNavigate();

  const [modelOpen, setCreateNewModalOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [currentObject, setCurrentObject] = useState(null);
  const [selectValue, setSelectValue] = useState('year');
  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => {
            setCurrentObject(f);
            setDisplayModal(true);
          }}>
          <Eye size={16} />
        </ActionIcon>
        <ActionIcon
          name="delete"
          color="red"
          onClick={async () => { f.deleteResource(id); }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    );

  };

  const setCreateNewModal = (createResourceFunction) => {
    setCreateNewModalOpen(true);
  }

  const objectSetting = {
    createNewModal: true,
    resources: `notifications`,
    tool: false,
    schema: [
      { name: 'CreatedAt', label: 'Datetime', sort: false, filter: false, display: (o, f) => <Text>{dayjs(o.CreatedAt).format('DD/MM/YYYY HH:mm')}</Text>, width: 150 },
      { name: 'Message', label: 'Message', sort: false, filter: false },
      // { name: 'actions', label: '', textAlignment: 'right', display: (o, f) => <Actions id={o.id} f={o} /> },
    ],
    action: [
    ],
    createAllowed: false,
  };
  return (
    <ScrollArea h={"calc(100vh - 80px)"}>
      <Space h="md" />
      <Center>
        <Title>Notifications</Title>&nbsp;&nbsp;
        <Select
          onChange={setSelectValue}
          value={selectValue}
          placeholder="Pick one"
          data={[
            { value: 'year', label: 'year' },
            { value: 'month', label: 'month' },
            { value: 'day', label: 'day' },
          ]}
        />
      </Center>
      <Space h="md" />

      <Modal opened={displayModal} onClose={() => setDisplayModal(false)} size="lg" title="Object detail">
        <Text>{currentObject?.filename}</Text>
        <Text>{currentObject?.createdAt}</Text>
        <Space h="md" />
        <Viewer
          fileUrl={currentObject?.url || ''}
        />
      </Modal>
      {
        selectValue === 'year' ? <AdminListView filter={{by:'year'}} noSearch setCreateNewModal={setCreateNewModal} {...objectSetting} />:null
      }
      {
        selectValue === 'month' ? <AdminListView filter={{by:'month'}} noSearch setCreateNewModal={setCreateNewModal} {...objectSetting} />:null
      }
      {
        selectValue === 'day' ? <AdminListView filter={{by:'day'}} noSearch setCreateNewModal={setCreateNewModal} {...objectSetting} />:null
      }
    </ScrollArea>
  );
};

export default NotificationList;