import { Table, Center, Title, Group } from "@mantine/core";

export default function Top5Candidates (props) {
	// table for top 5 jobs
	return (
		<Group position="center">
			<Center><Title>Top 5 Candidates (3 months)</Title></Center>
			<Table
				variant="hover"
				withBorder
				withRadius
				striped
			>
				<thead>
					<tr>
						<th>Candidate</th>
						<th>Hired</th>
						<th>Offered</th>
						<th>Second Interview</th>
						<th>Client Interviews</th>
						<th>Presented</th>
						<th>Consultant Interview</th>
						<th>Selected</th>
					</tr>
				</thead>
				<tbody>
					{props.data.map((column) => {
						return (
							<tr>
								<td><a target='_blank' href={`/candidate/${column.Id}`}>{column.Name}</a></td>
								<td>{column.Hired}</td>
								<td>{column.Offered}</td>
								<td>{column.SecondInterviews}</td>
								<td>{column.ClientInterviews}</td>
								<td>{column.Presented}</td>
								<td>{column.ConsultantInterview}</td>
								<td>{column.Selected}</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</Group>
	)
}