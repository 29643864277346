import { Group, Title, Center, Flex, ScrollArea, Select } from '@mantine/core';
import StatsGridIcons from './StatsGridIcons';
import Top5Jobs from './Top5Jobs';
import Top5Candidates from './Top5Candidates';
import api from '../../api';
import { useEffect, useState } from 'react';



export default function Report() {

	const [report, setReport] = useState({});
	const [candidateReport, setCandidateReport] = useState([]);
	const [jobReport, setJobReport] = useState([]);
	const [selectValue, setSelectValue] = useState('year');

	useEffect(() => {
		const getReport = async () => {
			const topline = await api().get(`/stats/topline?by=${selectValue}`)
			setReport(topline.data)
			const candidateReport = await api().get(`/stats/candidates?by=${selectValue}`)
			setCandidateReport(candidateReport.data)
			const jobReport = await api().get(`/stats/jobs?by=${selectValue}`)
			setJobReport(jobReport.data)
		}
		getReport()
	}, [selectValue])

	return <ScrollArea h={"calc(100vh - 80px)"}>
		<br />
		<Center>
			<Title>Monthly Stats</Title>&nbsp;&nbsp;
			<Select
				onChange={setSelectValue}
				value={selectValue}
				placeholder="Pick one"
				data={[
					{ value: 'year', label: 'year' },
					{ value: 'month', label: 'month' },
					{ value: 'day', label: 'day' },
				]}
			/>
		</Center>
		<StatsGridIcons data={report?.topLine?.data || []} />
		<Flex spacing="md" justify="space-around">
			<Top5Jobs
				data={jobReport}
			/>
		</Flex>
		<br />
		<Flex spacing="md" justify="space-around">
			<Top5Candidates data={candidateReport} />
		</Flex>
	</ScrollArea>
}