import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MultiSelect, PasswordInput, Container, Grid, Title, Menu, UnstyledButton, Tabs, Box, Checkbox, TextInput, Switch, Select, Button, Card, Group, Text, Center } from '@mantine/core';
import { EyeOff, EyeCheck } from 'tabler-icons-react';
import QRCode from "react-qr-code";
import { IconSettings } from '@tabler/icons';
import EditableMultiSelect from "../components/editableMultiSelect";
import md5 from 'js-md5';
import DatePicker from 'react-date-picker';

const UserDetailView = ({
	userObject,
	createResource,
	resourcesUpdated,
	updateResource
}) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [password, setPassword] = useState('');
	const tabParam = searchParams.get('tab') || 'summary';
	const [user, setUser] = useState(userObject);
	async function handleEdit(variable, data) {
		if (data == user[variable]) return;
		setUser({ ...user, [variable]: data });
	}

	async function handleSave() {
		if (user?.create) {
			await createResource({ ...user, isEmailInvitation:user.isEmailInvitation? user.isEmailInvitation:false});
		} else {
			await updateResource(user.id, { ...user, password });
		}
		navigate('/admin/user');
	}

	const roleData = [
		{ label: 'Super Admin', value: 'SUPER_ADMIN' },
		{ label: 'Candidate Provider', value: 'CANDIDATE_PROVIDER' },
		{ label: 'Candidate', value: 'CANDIDATE' },
		{ label: 'Job Owner', value: 'JOB_OWNER' },
		{ label: 'Job Client', value: 'JOB_CLIENT' },
	];

	React.useEffect(() => {
		// if user is new, we generate a password for them
		if (user?.create) {
			const hash = user?.email ? md5(user.email) : '123';
			// alert(md5(user.email).substring(0, 6))
			setPassword(hash.substring(0, 6));
		}
	}, [user, user?.email])

	return (
		<Container sx={(theme) => ({
			marginTop: theme.spacing.md,
			maxWidth: '100%',
		})}>
			<Grid sx={(theme) => ({ borderRadius: 3, backgroundColor: theme.colors.brand[0], padding: theme.spacing.md })}>
				<Grid.Col span={10}>
					<Title order={1} sx={(theme) => ({
						color: theme.colors.black[0],
						paddingBottom: theme.spacing.xs,
						'@media (max-width: 768px)': {
							fontSize: '1.5rem',
							paddingTop: theme.spacing.xs,
						}
					})}>
						{!user?.isNew ? user.name : 'Create User'}
					</Title>
				</Grid.Col>
				<Grid.Col>
					<TextInput
						label="Name"
						value={user.name}
						onChange={(e) => handleEdit('name', e.target.value)}
						sx={{ width: '100%', marginBottom: '20px' }}
					/>
					<TextInput
						label="Email"
						value={user.email}
						sx={{ width: '100%', marginBottom: '20px' }}
						onChange={(e) => handleEdit('email', e.target.value)}
					/>
					{user?.isNew ? <Checkbox
						value={user.isEmailInvitation}
						sx={{ width: '100%', marginBottom: '20px' }}
						onChange={(e) => handleEdit('isEmailInvitation', e.currentTarget.checked)}
						label="Send the invitation code to this email"
					/> : null}
					{/* add an expriy date that only editable if user role is SUPERADMIN */}
					{/* if user is not SUPERADMIN, user can view the expriy date */}
					{/*use date picker to select the date */}
					{(user?.roles?.includes('SUPER_ADMIN') || user?.isNew) ? <><div>Expiry Date</div><DatePicker label="Expiry Date" value={user.expiryDate} onChange={(e) => handleEdit('expiryDate', e)} /></> : <Text>Expiry Date: {user.expiryDate}</Text>}
					<MultiSelect
						data={roleData}
						value={user.roles}
						onChange={(e) => handleEdit('roles', e)}
						label="Role(s):"
						placeholder="Select role(s)"
						sx={{ width: '100%', marginBottom: '20px' }}
					/>
					<EditableMultiSelect
						onEdit={async (e) => {
							await handleEdit('tag', e);
						}}
						label='Tag'
						addNewOption
						value={user.tag}
						data={(user.tag ? user.tag : []).map((t) => ({ label: t, value: t }))}
					/>
					{(user?.isNew) && <div>
						<Center>
							<Card withBorder shadow="sm" radius="md">
								<Card.Section withBorder inheritPadding py="xs">
									<Group position="apart">
										<Text weight={500}>Invitation QR</Text>
									</Group>
								</Card.Section>
								<br />
								<Center>
									<QRCode value={`${window.location.host}/autoLogin?u=${user.email}&p=${password}`} />
								</Center>
							</Card>
						</Center>
					</div> || <>
							<PasswordInput
								label="New Password"
								placeholder="reset new password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								visibilityToggleIcon={({ reveal, size }) =>
									reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
								}
								sx={{ width: '100%', marginBottom: '20px' }}
							/></>
					}
					<TextInput
						label="Candidate id (Candidate only)"
						value={user.candidateId}
						sx={{ width: '100%', marginBottom: '20px' }}
						onChange={(e) => handleEdit('candidateId', e.target.value)}
					/>
					<TextInput
						label="Job id (Job Owner only)"
						value={user.candidateId}
						sx={{ width: '100%', marginBottom: '20px' }}
						onChange={(e) => handleEdit('candidateId', e.target.value)}
					/>
					<TextInput
						label="Client id (Job Client only)"
						value={user.candidateId}
						sx={{ width: '100%', marginBottom: '20px' }}
						onChange={(e) => handleEdit('candidateId', e.target.value)}
					/>
					<Button onClick={() => handleSave()}>Save</Button>
				</Grid.Col>
			</Grid>
		</Container>
	);
};
export default UserDetailView; 