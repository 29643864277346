import { useState } from 'react';
import axios from '../api';

const useJobStore = () => {
	const [job, setJob] = useState({
		JobReference: null,
		PositionName: null,
		Location: null,
		Address: null,
		Headcount: 0,
		ExperienceLevel: null,
		ExpectedCloseAt: null,
		MinSalary: 0,
		MaxSalary: 0,
		SalaryCurrencyCode: null,
		Frequency: null,
		ContractDetails: null,
		OpenAt: null,
		CloseAt: null,
		ClientId: null,
		Client: {},
		Specialities: [],
		IsRemote: false,
		JobDescription: null,
		PackageDetails: null,
		Remarks: null,
		Candidates: [],
	});
	const [jobCandidates, setJobCandidates] = useState([]);

	const findJobs = async (_page, _search) => {
		const { data } = await axios().get(`/jobs`, { params: { page: _page, search: _search, sort: 'CreatedAt', order: 'desc' } });
		return data; // data = {data: [], total: 0, page: 0, limit: 0}
	}

	const getJobByID = async (id) => {
		// clear jobCandidates
		setJobCandidates([]);
		// clear job
		setJob({});
		console.log('getJobByID', id);
		const response = await axios().get(`/jobs/${id}`);
		setJob(response.data);
		getCandidatesByJobID(id);
	}

	const setJobByID = async (id, job) => {
		await axios().post(`/jobs/${id}`, job);
		getJobByID(id);
	}

	const getCandidatesByJobID = async (id) => {
		const { data } = await axios().get(`/jobs/${id}/shortlist`);
		setJobCandidates(data || []);
	}

	const setCandidatesStatus = async (jobID, candidateID, status) => {
		await axios().put(`/jobs/${jobID}/shortlist/${candidateID}`, {
			Status: status,
		});
	}

	const removeCandidateFromJob = async (jobID, candidateID) => {
		await axios().delete(`/jobs/${jobID}/shortlist/${candidateID}`);
		alert('Candidate removed from job');
		await getCandidatesByJobID(jobID);
	}

	const setCandidatesToJobByJobID = async (jobID, candidateIDs) => {
		await axios().post(`/jobs/${jobID}/apply`, {
			JobId: jobID,
			CandidateIds: candidateIDs,
		});
	}


  return {
		job,
		jobCandidates,
		removeCandidateFromJob,
		getCandidatesByJobID,
		setCandidatesStatus,
		getJobByID,
		setJobByID,
		findJobs,
		setCandidatesToJobByJobID,
  }
}
export default useJobStore
