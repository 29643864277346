import AdminListView from "../components/adminListView";
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Badge, ActionIcon, Group, Space, ScrollArea, Tabs,Button } from "@mantine/core";
import { Dots, Edit, Eye, Search, Trash } from 'tabler-icons-react';
import Header from "../components/header";

const UsersList = (props) => {
  const navigate = useNavigate();

  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => navigate({
            pathname: `/admin/user/${id}`,
          })}>
          <Eye size={16} />
        </ActionIcon>
        <ActionIcon
          name="delete"
          color="red"
          onClick={async () => { f.deleteResource(id); }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    );

  };


  const userSetting = {
    resources: `users`,
    schema: [
      { name: 'name', label: 'name', sort: true, filter: true },
      {
        name: 'email', label: 'email', sort: true, filter: true, display: (o) => <div>{
          o.email
        } {!!o.isNew ? <Badge color="red">PENDING</Badge> : <></>}</div>
      },
      { name: 'roles', label: 'roles', sort: true, filter: false, display: (o) => <div>{o.roles.map(r => <Badge radius="xs" variant="outline">{r}</Badge>)}</div> },
      { name: 'tag', label: 'tags', sort: true, filter: false, display: (o) => <div>{o.tag?.map(r => <Badge radius="xs" variant="outline">{r}</Badge>)}</div> },
      { name: 'actions', label: '', textAlignment: 'right', display: (o, f) => <Actions id={o.id} f={f} /> },
    ],
    action: [
    ],
    createAllowed: true,
  };
  const roleData = [
    { label: 'Super Admin', value: 'SUPER_ADMIN' },
    { label: 'Candidate Provider', value: 'CANDIDATE_PROVIDER' },
    { label: 'Candidate', value: 'CANDIDATE' },
    { label: 'Job Owner', value: 'JOB_OWNER' },
    { label: 'Job Client', value: 'JOB_CLIENT' },
  ];
  return (
    <ScrollArea h={"calc(100vh - 80px)"}>
      <Header>
        Users
      </Header>
      <Button
						size='xs'
						onClick={() => navigate("group")}
					>
						Group
					</Button>
      <Space h="md" />
      <Tabs keepMounted={false} defaultValue="SUPER_ADMIN">
        <Tabs.List>
          {
            roleData.map((item) =>
              <Tabs.Tab value={item.value} >{item.label}</Tabs.Tab>
            )
          }
        </Tabs.List>
        {
          roleData.map((item) =>
            <Tabs.Panel value={item.value} pt="xs">
              <AdminListView filter={{ filter: item.value }} {...userSetting} pageName='user'/>
            </Tabs.Panel>
          )
        }
      </Tabs>
    </ScrollArea>
  );
};

export default UsersList;