import AdminListView from "../components/adminListView";
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Badge, ActionIcon, Group, Space, Button, Modal, Text, Input } from "@mantine/core";
import { Dots, Edit, Eye, Search, Trash } from 'tabler-icons-react';
import Header from "../components/header";
import { objectMockData } from "../mock/object";
import { useState } from "react";
import DefaultPdfLayout from "../components/pdfViewer/defaultPdfLayout";
import { Viewer } from "@react-pdf-viewer/core";

const ObjectList = (props) => {
  const navigate = useNavigate();

	const [modelOpen, setCreateNewModalOpen] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [currentObject, setCurrentObject] = useState(null);

  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => {
						setCurrentObject(f);
						setDisplayModal(true);
					}}>
          <Eye size={16} />
        </ActionIcon>
        <ActionIcon
          name="delete"
          color="red"
          onClick={async () => { f.deleteResource(id); }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    );

  };


	const setCreateNewModal = (createResourceFunction) => {
		setCreateNewModalOpen(true);
	}

  const objectSetting = {
		createNewModal: true,
    resources: `uploads`,
    schema: [
			{ name: 'filename', label: 'filename', sort: true, filter: true },
			{ name: 'url', label: 'url', sort: true, filter: true },
			{ name: 'type', label: 'type', sort: true, filter: true },
      { name: 'actions', label: '', textAlignment: 'right', display: (o, f) => <Actions id={o.id} f={o} /> },
    ],
    action: [
    ],
    createAllowed: true,
  };
  return (
    <>
      <Header>
        Files / Objects
      </Header>
      <Space h="md" />
			<Modal opened={modelOpen} onClose={() => setCreateNewModalOpen(false)} size="auto" title="Create new object">
				<Text>Create a new object</Text>
				<Space h="md" />
				<Input placeholder="Name" />
				<Space h="md" />
				<Group mt="xl" style={{ height: '300px', width: '600px' }}>
					<DefaultPdfLayout
						handleDrop={async (resume) => {}}
					/>
				</Group>
        <Group mt="xl">
          <Button variant="outline">
            Upload Object / File
          </Button>
        </Group>
      </Modal>

			<Modal opened={displayModal} onClose={() => setDisplayModal(false)} size="lg" title="Object detail">
				<Text>{currentObject?.filename}</Text>
				<Text>{currentObject?.createdAt}</Text>
				<Space h="md" />
				<Viewer
					fileUrl={currentObject?.url || ''}
				/>
			</Modal>
      <AdminListView setCreateNewModal={setCreateNewModal} {...objectSetting} />
    </>
  );
};

export default ObjectList;