import axios from "../api";
import { useEffect, useState } from 'react';

const useCandidateSearchStore = () => {
	const [activeSearchId, _setActiveSearchId] = useState(0);
	const [limit, _setLimit] = useState(10);
	const [sortBy, setSortBy] = useState([]);
	const [iQuery, setIQuery] = useState({ combinator: 'and', rules: [] });
	const [search, setSearch] = useState([
		{ uuid: "", id: 0, name: 'New Search', iQuery: { combinator: 'and', rules: [] } },
	]);
	const [searchState, _setSearchState] = useState([{
		data: [],
		name: 'New Search',
		sql: '1=1',
		iQuery: { combinator: 'and', rules: [] },
		sort: [{}],
		total: 0,
		page: 1,
		id: "",
	}])

	const newSerach =	{ id: 0, name: 'New Search' }

	const findAllSearch = async () => {
		const { data } = await axios().get(`/searches`);
		setSearch([newSerach, ...data.map((d, k) => (
			{
				id: k + 1,
				name: d.name,
				sql: d.sql,
				iQuery: JSON.parse(d.iQuery),
				uuid: d.id
			}
		))]);
	}

	const setActiveSearchWithId = (id) => {
		if (!!searchState[id]) {
			console.log('setting activeSearchId', id)
			setIQuery(searchState[id].iQuery)
			_setActiveSearchId(id)
		} else {
			const newSearchState = {
				data: [],
				id: search.find(s => s.id === id)?.uuid,
				name: search.find(s => s.id === id)?.name || 'New Search',
				sql: search.find(s => s.id === id)?.sql || '1 = 1',
				iQuery: search.find(s => s.id === id)?.iQuery || { combinator: 'and', rules: [] },
				sort: [{}],
				total: 0,
				page: 1,
			}
			setIQuery(newSearchState.iQuery)
			setSearchState(id, newSearchState)
			_setActiveSearchId(id)
		}
	}

	useEffect(() => {
		console.log('searchState', searchState)
		console.log('activeSearchId', activeSearchId)
	}, [searchState])

	useEffect(() => {
		console.log('fetching activeSearchId', activeSearchId)
		getCandidates(activeSearchId, searchState[activeSearchId]['sql'])
	}, [activeSearchId])

	useEffect(() => {
		console.log('--- iQuery listened', iQuery)
		setSearchState(activeSearchId, {
			data: searchState[activeSearchId]['data'],
			name: searchState[activeSearchId]['name'],
			sql: searchState[activeSearchId]['sql'],
			iQuery,
			total: searchState[activeSearchId]['total'],
			sort: searchState[activeSearchId]['sort'],
			page: searchState[activeSearchId]['page'],
		})
	}, [iQuery])

	const setSearchState = (id, state) => {
		const newSearchState = [...searchState]
		newSearchState[id] = state
		_setSearchState(newSearchState)
	}

	const setPage = (page) => {
		setSearchState(activeSearchId, {
			data: searchState[activeSearchId]['data'],
			name: searchState[activeSearchId]['name'],
			sql: searchState[activeSearchId]['sql'],
			iQuery,
			total: searchState[activeSearchId]['total'],
			sort: searchState[activeSearchId]['sort'],
			page,
		})
		requestQuery(searchState[activeSearchId]['sql'], page, searchState[activeSearchId]['sort'])
	}

	const setLimit = (limit) => {
		_setLimit(limit)
		requestQuery(searchState[activeSearchId]['sql'], searchState[activeSearchId]['page'], searchState[activeSearchId]['sort'], limit)
	}

	const compileQueryString = (sorting) => {
		console.log('sorting', sorting)
		const columnNames = [];
		const order = [];

		sorting.forEach(sortObj => {
			for (const [column, sortOrder] of Object.entries(sortObj)) {
				columnNames.push(column);
				order.push(sortOrder?.toLowerCase() === 'asc' ? 'asc' : 'desc');
			}
		});

		const queryString = `sort=${encodeURIComponent(columnNames.join(','))}&order=${encodeURIComponent(order.join(','))}`;
		return queryString;
	}

	const getCandidates = async (id, sql) => {
		const form = new FormData();
		form.append('query', sql);
		const res = await axios().post(process.env.REACT_APP_API_URL + `/candidates/query?page=${searchState[id]['page']}&limit=${limit}&${compileQueryString(sortBy)}`, form)
		console.log('res', res)
		setLimit(res.data.limit)
		console.log(res.data)
		setSearchState(id, {
			id: search[id].uuid,
			data: res.data.data,
			name: search[id].name,
			iQuery,
			sql,
			total: res.data.total,
			sort: sortBy,
			page: res.data.page,
		})
	}

	const requestQuery = async (sql, page, sortBy, _limit, iQuery) => {
		if (!page) {
			page = searchState[activeSearchId]['page']
		}
		if (!sortBy) {
			sortBy = searchState[activeSearchId]['sort']
		}
		if (!_limit) {
			_limit = limit
		}
		if (!iQuery) {
			iQuery = searchState[activeSearchId]['iQuery']
		}

		// reset page to 1 if the query has changed
		if (searchState[activeSearchId]['sql'] !== sql) {
			page = 1
		}

		const form = new FormData();
		form.append('query', sql);
		const res = await axios().post(process.env.REACT_APP_API_URL + `/candidates/query?page=${page}&limit=${_limit}&${compileQueryString(sortBy)}`, form)
		setSearchState(activeSearchId, {
			id: search[activeSearchId].uuid,
			data: res.data.data,
			name: search[activeSearchId].name,
			sort: sortBy,
			iQuery,
			sql,
			total: res.data.total,
			page: res.data.page,
		})
	}

	const setSort = (sort) => {
		setSortBy(sort)
		console.log(sort)
		setSearchState(activeSearchId, {
			id: search[activeSearchId].uuid,
			data: searchState[activeSearchId]['data'],
			name: searchState[activeSearchId]['name'],
			sql: searchState[activeSearchId]['sql'],
			iQuery,
			total: searchState[activeSearchId]['total'],
			page: searchState[activeSearchId]['page'],
			sort,
		})
		requestQuery(searchState[activeSearchId]['sql'], searchState[activeSearchId]['page'], sort)
	}

	const onQuerySubmit = async (query, sql, jsonNoId) => {
		setIQuery(query)
		requestQuery(sql, false, false, false, query)
	}

	const refreshCurrentSearch = async () => {
		requestQuery(searchState[activeSearchId]['sql'], searchState[activeSearchId]['page'], searchState[activeSearchId]['sort'], limit, iQuery)
	}

	return {
		search,
		activeSearchId,
		sortBy,
		searchState,
		limit,
		refreshCurrentSearch,
		findAllSearch,
		setSearchState,
		setActiveSearchWithId,
		setPage,
		setLimit,
		setSort,
		onQuerySubmit,
		getCandidates,
	}
}

export default useCandidateSearchStore