import { useState } from 'react';
import axios from '../api';

const useClientStore = () => {
	const [client, setClient] = useState({
		Name: '',
		Specialities: [],
		Address: '',
		Location: '',
		Remark: '',
		created_at: '',
		updated_at: '',
	})
	// const [clients, setClients] = useState([]); // [{}

	const findClients = async (_page, _search) => {
		const { data } = await axios().get(`/clients?limit=99999`, { params: { page: _page, search: _search } });
		return data; // data = {data: [], total: 0, page: 0, limit: 0}
	}

	const getClientByID = async (id) => {
		console.log('getClientByID', id);
		const response = await axios().get(`/clients/${id}`);
		setClient(response.data);
	}

	return {
		client,
		findClients,
		getClientByID,
	};
};

export default useClientStore;
