import React from 'react';
import useJobStote from './useJobStore';
import useCandidateStore from './useCandidateStore';
import useClientStore from './useClientStore';
import useFieldStore from './useFieldStore';
import useCandidateSearchStore from './useCandidateSearchStore';

const CandidateSearchStoreContext = React.createContext();
const CandidateStoreContext = React.createContext();
const JobStoreContext = React.createContext();
const ClientStoreContext = React.createContext();
const FieldStoreContext = React.createContext();

export const useStoreProvider = ({ children }) => {
	const candidateSearchStore = useCandidateSearchStore();
	const candidateStore = useCandidateStore();
	const jobStore = useJobStote();
	const clientStore = useClientStore();
	const fieldStore = useFieldStore();

	return (
		<CandidateSearchStoreContext.Provider value={candidateSearchStore}>
			<CandidateStoreContext.Provider value={candidateStore}>
				<JobStoreContext.Provider value={jobStore}>
					<ClientStoreContext.Provider value={clientStore}>
						<FieldStoreContext.Provider value={fieldStore}>
							{children}
						</FieldStoreContext.Provider>
					</ClientStoreContext.Provider>
				</JobStoreContext.Provider>
			</CandidateStoreContext.Provider>
		</CandidateSearchStoreContext.Provider>
	);
};

export const useCandidateSearchStoreContext = () => React.useContext(CandidateSearchStoreContext);
export const useCandidateStoreContext = () => React.useContext(CandidateStoreContext);
export const useJobStoreContext = () => React.useContext(JobStoreContext);
export const useClientStoreContext = () => React.useContext(ClientStoreContext);
export const useFieldStoreContext = () => React.useContext(FieldStoreContext);


export default useStoreProvider;