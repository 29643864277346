import { Table, Center, Title, Group } from "@mantine/core";

export default function Top5Jobs (props) {
	// table for top 5 jobs
	return (
		<Group position="center">
			<Center><Title>Top 5 Jobs</Title></Center>
			<Table
				variant="hover"
				withBorder
				withRadius
				striped
			>
				<thead>
					<tr>
						<th>Job</th>
						<th>Company</th>
						<th>Candidate Counts</th>
					</tr>
				</thead>
				<tbody>
					{props.data.map((column) => {
						return <tr>
							<td><a target="_blank" href={`/job/${column.ID}`}>{column.Name}</a></td>
							<td>{column.Company}</td>
							<td>{column.CandidateCount}</td>
						</tr>
					})}
				</tbody>
			</Table>
		</Group>
	)
}