import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import UserDetailView from './userDetailView';
import { ScrollArea } from '@mantine/core';

const UsersView = () => {
  const { params: { id: usersId } } = useMatch('/admin/user/:id');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource,
    reloadResources, } = useCURDStore({
			resourcesName: 'users',
			defaultValue: {
				isNew: true,
				create: true,
			}
		});

  useEffect(() => {
    const fetchCandidate = async () => {
			if (usersId === 'create') return;
      await getResource(usersId);
    };
    fetchCandidate();
  }, [usersId]);

	return <ScrollArea h={"calc(100vh - 80px)"}>{(!resource?.id && (usersId !== 'create')) && <div>Loading...</div> ||
    <UserDetailView {...{
      userObject: resource,
      resourcesUpdated,
      updateResource,
			createResource
    }} />
  }</ScrollArea>;

};

export default UsersView;